<template>
  <v-row
    v-show="isLoading"
    class="fill-height"
    align-content="center"
    justify="center"
  >
    <v-col
      style="font-size: 0.9rem; color: #7a7a7a"
      class="text-center"
      cols="12"
    >
      获取单词中
    </v-col>
    <v-col cols="12">
      <v-progress-linear
        color="#a3aeb3"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "loadingPage",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped></style>
