<template>
  <div class="content">
    <div class="word-card">
      <v-card
        class="rounded-lg card"
        height="calc(100% - 1.8rem)"
        elevation="0"
        v-touch="{
          up: flashSwipeUp,
        }"
      >
        <div v-show="false">
          <audio
            ref="pronounce"
            v-if="!isEmpty(current) && current.audio"
            preload="auto"
            :src="current.audio"
          />
        </div>
        <div class="cover">
          <div
            class="empty"
            v-if="(isEmpty(current) && !isLoading) || finished"
          >
            <van-empty
              class="v-empty"
              :image="
                offline
                  ? 'network'
                  : require('@/assets/svgimgs/finish/undraw_awesome_rlvy.svg')
              "
            >
              <div class="desc" v-show="!offline">
                <p>已完成推荐单词的学习<br />打卡后仍可以继续学习</p>
                <p style="color: #bbbbbb">
                  尚未达成您的目标，可<br />以向单词本添加更多单<br />词来学习更多！
                </p>
              </div>
              <div class="desc" v-show="offline">网络出错啦！</div>
            </van-empty>
            <v-btn
              v-show="!offline"
              class="ma-2 rounded-lg"
              outlined
              color="#ffa500"
              @click="binGo"
              :loading="binGoLoading"
            >
              <v-icon
                style="margin-right: 4px"
                v-show="records.bingo"
                left
                dark
              >
                mdi-check-circle
              </v-icon>
              {{ records.bingo ? "已打卡" : "立刻打卡" }}
            </v-btn>
            <div class="empty-back">
              <v-btn
                v-show="!offline"
                class="ma-2 rounded-lg"
                outlined
                color="#909090"
                @click="goIndex"
              >
                回到首页
              </v-btn>
              <v-btn
                v-show="offline"
                class="ma-2"
                outlined
                color="#ccc3a5"
                @click="refresh"
              >
                重新获取
              </v-btn>
            </div>
          </div>
        </div>
        <v-expand-transition>
          <v-card
            v-if="!finished"
            elevation="0"
            class="transition-fast-in-fast-out v-card--reveal rounded-lg word-detail"
            style="height: 100%"
          >
            <error-page
              @refresh="refresh"
              v-if="isEmpty(current) && !isLoading"
            ></error-page>
            <loading-page :isLoading="isLoading"></loading-page>
            <div class="word-detail">
              <div class="word-title mt-10 mb-5">{{ current.word }}</div>
              <div
                class="phonetic"
                v-show="current.phonetic"
                style="margin-left: -0.5px"
              >
                <van-tag class="tag" size="large"
                  >/ {{ current.phonetic }} /
                </van-tag>
                <v-btn
                  class="ml-3"
                  fab
                  plain
                  x-small
                  elevation="0"
                  color="#ffffff"
                  :disabled="isPlaying"
                  @click="playAudio"
                >
                  <v-icon color="blue-grey darken-2">mdi-account-voice</v-icon>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div
                class="transation"
                v-show="current.translation"
                v-html="formatTranslate(current.translation)"
              ></div>
              <v-btn
                outlined
                class="mt-10"
                small
                @click="goDictionary"
                style="display: flex"
                color="#909090"
              >
                在词典中查看 —&gt;
              </v-btn>
            </div>
          </v-card>
        </v-expand-transition>
      </v-card>
    </div>

    <div class="btn-all rounded-lg" v-show="!isEmpty(current) && !finished">
      <transition name="btn">
        <div class="btn-gp rounded-lg">
          <v-btn
            large
            elevation="1"
            width="100%"
            height="3.1rem"
            class="rounded-lg"
            color="#ffffff"
            @click="flashNextWord"
            :loading="nextLoading"
            >下一个单词 —&gt;
            <template v-slot:loader>
              云同步中
              <span class="ml-1 custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </div>
      </transition>
    </div>
    <v-dialog v-model="deleteConfirmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> 将此单词标记为熟知？</v-card-title>
        <v-card-text v-show="!meetPurpose"
          >标记熟知后，将不再出现在单词复习中。</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteConfirmDialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
            :loading="deleteLoading"
          >
            确认移除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { learnWord } from "@/mixin/learnWordMixin";
import { mapGetters } from "vuex";
import { getDayStr } from "@/utils/common";
import $api from "@/api/api";

export default {
  name: "learnFlash",
  components: {},
  mixins: [learnWord],
  computed: {
    ...mapGetters({
      learnTemp: "learnTemp",
    }),
    finished() {
      return this.currentIndex >= this.wordList.length;
    },
    meetPurpose() {
      return (
        this.records.new + this.records.review >=
        this.userPreferrence.gole.newWordGole +
          this.userPreferrence.gole.reviewWordGole
      );
    },
  },
  data: () => ({
    type: 2, // 1新词，0复习
    dateStr: getDayStr(),
    binGoLoading: false,
  }),
  methods: {
    binGo() {
      this.zzzShort();
      this.binGoLoading = true;
      if (this.records.bingo) {
        this.binGoLoading = false;
        return this.$toast("您已完成了打卡！");
      }
      let postData = {
        type: 0, // 0词汇1课程
      };
      $api
        .binGo(postData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.records.bingo = true;
            this.$toast.success("打卡成功！");
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.binGoLoading = false;
        });
    },
    flashSwipeUp(e) {
      if (e.touchstartY - e.touchendY > 150) {
        this.flashNextWord();
      }
    },
    flashNextWord() {
      if (this.finished) {
        this.$toast("您已完成推荐单词的学习！");
      } else {
        this.currentIndex += 1;
        this.$store.dispatch("setLearnTempFlashIndex", {
          index: this.currentIndex,
          date: this.dateStr,
        });
        this.current = this.wordList[this.currentIndex];
      }
    },
  },
  created() {
    if (this.dateStr === this.learnTemp.flashIndex.date) {
      this.currentIndex = this.learnTemp.flashIndex.index;
    } else {
      this.currentIndex = 0;
      this.$store.dispatch("setLearnTempFlashIndex", {
        index: 0,
        date: this.dateStr,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../style/style";
.empty {
  margin: 0 auto;
  min-height: 8rem;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.8rem;
  color: #9f9f9f;
  text-align: center;
  background-color: #ffffff00;
  letter-spacing: 0.15rem;
  //> div {
  //  margin: 0.6rem;
  //}
  .v-empty {
    margin-top: -5rem;

    .desc {
      margin: 0.6rem;
    }
  }

  .empty-back {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0;
  }
}
</style>
