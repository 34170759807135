import { mapGetters } from "vuex";
import $api from "@/api/api";
import { buttonVibrate } from "@/mixin/mixin";
import { deepClone, isEmpty } from "@/utils/common";
import { Tag } from "vant";
import LoadingPage from "@/views/learnWord/components/loadingPage";

export const learnWord = {
  mixins: [buttonVibrate],
  components: {
    ErrorPage: () => import("@/views/learnWord/components/errorPage"),
    LoadingPage,
    vanTag: Tag,
  },
  computed: {
    ...mapGetters({
      touched: "touched",
      isApp: "isApp",
      appLoading: "appLoading",
      userPreferrence: "userPreferrence",
    }),
    formatTranslate() {
      return function (text) {
        if (!isEmpty(text)) {
          text = text.replace(/\\n/g, "<br />");
          text = text.replace(/&nbsp;/g, " ");
          return text;
        }
        return "";
      };
    },
    firstMeet() {
      return (
        !isEmpty(this.current) &&
        !this.current.currentRemember &&
        this.current.currentForget
      );
    },
  },
  data() {
    return {
      isLoading: false,
      isPlaying: false,
      playInterval: null,
      offline: false,
      // 单词背诵相关
      reveal: false,
      nextLoading: false,
      currentType: 0,
      currentIndex: 0,
      deleteConfirmDialog: false,
      deleteLoading: false,
      current: {},
      wordList: [],
      finishWordList: [],
      records: {
        new: 0,
        review: 0,
        time: 0,
        bingo: false,
      },
    };
  },
  methods: {
    isEmpty,
    goIndex() {
      this.zzzShort();
      this.$router.replace("/");
    },
    confirmDelete() {
      this.deleteLoading = true;
      let postData = {
        id: this.current.id,
      };
      $api
        .setKnown(postData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.wordList = this.wordList.filter((item) => {
              return item.id !== this.current.id;
            });
            if (this.currentIndex >= this.wordList.length - 1) {
              this.currentIndex = 0;
            }
            this.upCount();
            this.goNext();
            this.$toast(res.msg);
            this.deleteConfirmDialog = false;
          } else {
            this.$toast("出错了" + res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //关闭loading
          this.deleteLoading = false;
        });
    },
    updateTotal(num) {
      this.$emit("updateTotal", this.type, num);
    },
    updateCount(num) {
      this.$emit("updateCount", this.type, num);
    },
    upCount() {
      this.$emit("upCount", this.type);
    },
    syncWordProgress() {
      return new Promise((resolve, reject) => {
        if (!isEmpty(this.finishWordList)) {
          $api
            .syncWordProgress(this.finishWordList)
            .then((ret) => {
              let res = ret.data;
              if (res.code === 0) {
                resolve();
              } else {
                this.$toast("出错了" + res.msg);
                reject();
              }
            })
            .catch((err) => {
              console.log(err);
              reject();
            })
            .finally(() => {
              //关闭loading
              this.nextLoading = false;
            });
        } else {
          resolve();
        }
      });
    },
    goNext() {
      // console.log(this.wordList);
      // console.log("index", this.currentIndex);
      // console.log("length", this.wordList.length);
      if (this.wordList.length === 0) {
        this.syncWordProgress().then();
        this.$emit("nextStep");
        return;
      } else {
        let item = this.wordList[this.currentIndex];
        if (!item.currentLearned) {
          this.current = this.wordList[this.currentIndex];
          if (isEmpty(this.current.audio)) {
            this.getAudio();
          }
        }
      }
      this.reveal = false;
    },
    nextWord() {
      this.zzzShort();
      this.nextLoading = true;
      // 开启loading
      let temp = deepClone(this.current);
      this.syncSingleWord()
        .then(() => {
          this.goNext();
        })
        .catch(() => {
          this.current = temp;
        })
        .finally(() => {
          //关闭loading
          this.nextLoading = false;
        });
    },
    syncSingleWord() {
      return new Promise((resolve, reject) => {
        let postData = null;
        // 认识
        if (this.currentType === 1) {
          if (!this.current.currentRemember && !this.current.currentForget) {
            // 今天第一次学习就认识新单词
            this.current.currentLearned = true;
            this.current.consecutiveRemember = 1;
            this.current.currentForget = 0;
            this.current.currentRemember = 1;
            // 云同步进度
            postData = {
              id: this.current.id,
              currentLearned: this.current.currentLearned,
              currentRemember: this.current.currentRemember,
              currentForget: this.current.currentForget,
              consecutiveRemember: this.current.consecutiveRemember, // 连续记住次数
            };
            // 不是首次认识
          } else {
            // 不存在认识次数
            if (!this.current.currentRemember) {
              this.current.currentRemember = 1;
            } else {
              //存在认识次数
              this.current.currentRemember += 1;
            }
            // 不存在连续认识
            if (!this.current.consecutiveRemember) {
              this.current.consecutiveRemember = 1;
            } else {
              // 存在连续认识
              this.current.consecutiveRemember += 1;
              if (this.current.consecutiveRemember > 2) {
                // 连续三次以上记住
                this.current.currentLearned = true;
                // 云同步进度
                postData = {
                  id: this.current.id,
                  currentLearned: true,
                  currentRemember: this.current.currentRemember,
                  currentForget: this.current.currentForget,
                  consecutiveRemember: this.current.consecutiveRemember, // 连续记住次数
                };
              }
            }
          }
        }
        // 不认识
        if (this.currentType === 0) {
          if (!this.current.currentForget) {
            this.current.currentForget = 1;
          } else {
            this.current.currentForget += 1;
            if (
              this.current.currentForget === 3 ||
              this.current.currentForget === 6
            ) {
              if (this.type !== 1) {
                // 复习的话老是忘记，惩罚扣除熟知度
                // 云同步进度
                postData = {
                  id: this.current.id,
                  currentLearned: false,
                  currentRemember: this.current.currentRemember,
                  currentForget: this.current.currentForget,
                  consecutiveRemember: this.current.consecutiveRemember, // 连续记住次数
                };
              }
            }
          }
          // 不管是啥情况忘记都连续记住次数清零
          this.current.consecutiveRemember = 0;
        }
        // 接口云同步
        if (postData) {
          postData.type = this.type; // 1新词，0复习
          $api
            .syncSingleWord(postData)
            .then((ret) => {
              let res = ret.data;
              if (res.code === 0) {
                // 处理本地单词列表
                // 标记认识
                this.records = res.data.records;
                if (this.current.currentLearned) {
                  this.finishWordList.push(this.current);
                  this.wordList = this.wordList.filter((item) => {
                    return item.id !== this.current.id;
                  });
                  this.upCount();
                  if (this.currentIndex >= this.wordList.length - 1) {
                    this.currentIndex = 0;
                  }
                } else {
                  // 标记不认识
                  if (this.currentIndex >= this.wordList.length - 1) {
                    this.currentIndex = 0;
                  } else {
                    this.currentIndex += 1;
                  }
                }
                resolve();
              } else {
                this.$toast.error(res.msg);
                reject();
              }
            })
            .catch((err) => {
              console.log(err);
              reject();
            });
        } else {
          if (this.currentIndex >= this.wordList.length - 1) {
            this.currentIndex = 0;
          } else {
            this.currentIndex += 1;
          }
          resolve();
        }
      });
    },
    loadWords() {
      this.offline = false;
      this.isLoading = true;
      // this.$store.dispatch("setAppLoading", {
      //   show: true,
      //   opacity: 0.9,
      //   theme: "#e3fff8",
      //   color: "#e8961a",
      //   time: 1500,
      // });
      let getData = {
        type: this.type,
      };
      $api
        .getTodayWords(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            // 更新数据
            this.updateTotal(res.data.total);
            this.records = res.data.records;
            this.wordList = res.data.words;
            if (this.wordList.length === 0) {
              if (this.type === 2) {
                this.updateTotal(res.data.total);
              }
              this.$emit("nextStep");
              this.current = {};
              return;
            }
            if (this.type === 2) {
              this.current = this.wordList[this.currentIndex];
            } else {
              this.current = this.wordList[0];
            }
            if (!isEmpty(this.current) && isEmpty(this.current.audio)) {
              this.getAudio();
            }
            // 如果是第一次加载数据，今日新学记录=云同步记录数
            if (this.type === 0) {
              this.$emit("updateCount", 1, res.data.records.new);
            } // 如果是在新词界面加载数据，今日新学记录=0
            if (this.type === 1) {
              this.$emit("updateCount", 1, 0);
            }
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.offline = true;
        })
        .finally(() => {
          // 停止加载动画
          this.isLoading = false;
          // setTimeout(() => {
          //   this.$store.dispatch("setAppLoading", {
          //     show: false,
          //     opacity: 0.98,
          //     theme: "#e3e8ff",
          //     color: "#bac8ff",
          //     time: 1500,
          //   });
          // }, 500);
        });
    },
    swipeUp(e) {
      if (e.touchstartY - e.touchendY > 150) {
        if (this.nextLoading) {
          return this.$toast("您划的太快了，休息一下下！");
        }
        if (this.reveal) {
          if (this.currentType === 1) {
            this.nextWord();
          } else {
            this.reCheck();
          }
        } else {
          this.remember();
        }
      }
    },
    swipeDown(e) {
      if (e.touchendY - e.touchstartY > 150) {
        if (this.nextLoading) {
          return this.$toast("您划的太快了，休息一下下！");
        }
        if (this.reveal) {
          if (this.currentType === 0) {
            this.nextWord();
          } else {
            this.reCheck();
          }
        } else {
          this.forget();
        }
      }
    },
    refresh() {
      this.$emit("refresh");
    },
    reCheck() {
      this.zzzShort();
      this.reveal = false;
    },
    remember() {
      this.zzzShort();
      if (isEmpty(this.current)) {
        return;
      }
      this.currentType = 1;
      this.reveal = true;
    },
    forget() {
      this.zzzShort();
      if (isEmpty(this.current)) {
        return;
      }
      this.currentType = 0;
      this.reveal = true;
    },
    showDeleteDialog() {
      this.zzzShort();
      this.deleteConfirmDialog = true;
    },
    goDictionary() {
      this.zzzShort();
      this.$router.push({
        path: "/word_detail",
        query: {
          word: this.current.word,
          id: this.current.id,
        },
      });
    },
    getAudio() {
      if (/^[A-Za-z]+$/g.test(this.searchWord)) {
        let reqData = {
          word: this.searchWord,
        };
        $api
          .getWordPronounce(reqData)
          .then((ret) => {
            let res = ret.data;
            if (res.code === 0 && res.data.sdkAudio) {
              this.current.audio = res.data.sdkAudio;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    playAudio() {
      clearInterval(this.playInterval);
      this.playInterval = null;
      this.isPlaying = true;
      this.zzzMid();
      this.$refs.pronounce
        .play()
        .catch((err) => {
          console.log(err);
          this.$toast("播放出错,请重试!");
          let temp = this.current.audio;
          this.current.audio = null;
          setTimeout(() => {
            this.current.audio = temp;
            this.isPlaying = false;
          }, 500);
        })
        .finally(() => {
          this.playInterval = setInterval(() => {
            if (this.$refs.pronounce) {
              if (this.$refs.pronounce.ended) {
                this.isPlaying = false;
                clearInterval(this.playInterval);
              }
            } else {
              this.isPlaying = false;
              clearInterval(this.playInterval);
            }
          }, 250);
        });
    },
  },
  created() {
    this.loadWords();
  },
};
